@font-face {
  font-family: Sora;
  font-weight: 300;
  src: url("../assets/fonts/Sora-Light.ttf") format("truetype");
}

@font-face {
  font-family: Sora;
  font-weight: 400;
  src: url("../assets/fonts/Sora-Regular.ttf") format("truetype");
}

@font-face {
  font-family: Sora;
  font-weight: 600;
  src: url("../assets/fonts/Sora-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: Sora;
  font-weight: 700;
  src: url("../assets/fonts/Sora-Bold.ttf") format("truetype");
}
