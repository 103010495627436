@import "./font.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply bg-background font-sans text-sm text-primary;
  }

  .bg-app {
    background-image: url("./images/top-bg-gradient.png"),
      url("./images/bottom-bg-gradient.png");
    background-position: top, bottom;
    background-repeat: no-repeat, no-repeat;
    background-size: cover, cover;
  }

  /* Assets background image */
  .bg-asset-media {
    background-image: url("./images/asset_background.png");
    background-repeat: no-repeat;
    background-size: cover;
  }
}
